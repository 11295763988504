import * as _ from 'lodash';
import React from 'react';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import imagesLoaded from 'imagesloaded';
import s from './ProductList.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {DataHook as ProductImagesDataHook} from '../../../common/components/ProductItem/ProductImage/ProductImage';
import {EmptyGallery} from './EmptyGallery/EmptyGallery';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {LoadMoreButton} from './LoadMoreButton/LoadMoreButton';
import {ProductItem} from '../../../common/components/ProductItem/ProductItem';
import {withGlobals} from '../../../globalPropsContext';
import {Pagination} from 'wix-ui-core/pagination';
import paginationStyle from './pagination.st.css';
import {LoadMoreType} from '../../../types/galleryTypes';

export interface ProductListProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

interface ProductListState {
  inBrowser: boolean;
}

@withGlobals
@withTranslations()
@autobind
export class ProductList extends React.Component<ProductListProps, ProductListState> {
  private readonly productItemRefs = [];
  private readonly listRef: React.RefObject<HTMLUListElement>;
  private imagesLoaded = false;
  private isInteractive = false;
  public state = {inBrowser: false};

  public constructor(props) {
    super(props);

    this.listRef = React.createRef();
  }

  public componentDidMount(): void {
    this.setState({inBrowser: true}, () => {
      imagesLoaded(document.querySelectorAll(`[data-hook="${ProductImagesDataHook.Images}"]`), () => {
        this.imagesLoaded = true;
        this.reportLoad();
      });
    });
  }

  private reportLoad() {
    if (this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
    }
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    if (!this.isInteractive && this.props.globals.isInteractive) {
      this.isInteractive = true;
      /* istanbul ignore next: hard to test it */
      this.props.globals.updateLayout && this.props.globals.updateLayout();
      this.reportLoad();
    }

    if (this.shouldFocusOnNewlyDisplayedProduct(prevProps)) {
      this.productItemRefs[this.props.globals.focusedProductIndex].focus();
    }
  }

  public render() {
    const {products} = this.props.globals;
    return (
      <section data-hook="product-list" aria-label={this.props.t('galleryRegionSR')}>
        {products.length === 0 ? this.getEmptyList() : this.getProductList()}
      </section>
    );
  }

  private get maxProductsPerPage(): number {
    if (this.props.globals.isMobile) {
      return 10;
    }
    return this.props.globals.styleParams.numbers.galleryColumns * this.props.globals.styleParams.numbers.galleryRows;
  }

  private getEmptyList() {
    return <EmptyGallery hasFilters={this.props.hasFilters} />;
  }

  private shouldFocusOnNewlyDisplayedProduct(prevProps) {
    return (
      !this.props.globals.isFirstPage &&
      this.props.globals.focusedProductIndex !== prevProps.globals.focusedProductIndex
    );
  }

  private getNumberOfVisibleProducts(): number {
    return this.props.globals.isFirstPage ? this.maxProductsPerPage : this.props.globals.products.length;
  }

  private getProductList() {
    const {
      products,
      isMobile,
      styleParams: {
        booleans: {responsive: isResponsiveGallery},
      },
    } = this.props.globals;
    const style = isResponsiveGallery ? {gridTemplateColumns: null} : {};
    const gridClass = isResponsiveGallery ? s.productsGridResponsive : s.productsGrid;
    const currentElement = this.listRef.current;

    //istanbul ignore next: element does not have width in JSDOM
    if (isResponsiveGallery && currentElement && currentElement.clientWidth) {
      const cols = this.props.globals.styleParams.numbers.galleryColumns + 1;
      const minmaxWidth = this.listRef.current.clientWidth / cols;
      style.gridTemplateColumns = `repeat(auto-fill, minmax(${minmaxWidth}px, 1fr))`;
    }

    return (
      <>
        <ul
          ref={this.listRef}
          className={classNames(gridClass, {[s.isMobile]: isMobile})}
          data-hook="product-list-wrapper"
          style={style}>
          {products.slice(0, this.getNumberOfVisibleProducts()).map((product, index) => {
            return (
              <li key={product.id}>
                <ProductItem index={index} product={product} innerRef={c => (this.productItemRefs[index] = c)} />
              </li>
            );
          })}
        </ul>
        {this.renderLoadMore()}
      </>
    );
  }

  private renderLoadMore() {
    const totalProducts = this.props.globals.totalProducts;
    const loadMoreType = _.get(
      this.props.globals,
      ['styleParams', 'fonts', 'gallery_loadMoreProductsType', 'value'],
      LoadMoreType.BUTTON
    );

    switch (loadMoreType) {
      case LoadMoreType.PAGINATION:
        return this.maxProductsPerPage < totalProducts && this.renderPagination();
      case LoadMoreType.BUTTON:
      default:
        return this.shouldShowLoadMoreButton() && this.getLoadMoreButton();
    }
  }

  private shouldShowLoadMoreButton(): boolean {
    const {
      globals: {isFirstPage, hasMoreProductsToLoad, totalProducts},
    } = this.props;

    if (isFirstPage) {
      return this.maxProductsPerPage < totalProducts;
    }
    return hasMoreProductsToLoad;
  }

  private getLoadMoreButton() {
    return <LoadMoreButton loadMoreClicked={this.loadMoreClicked} />;
  }

  private async loadMoreClicked() {
    this.props.globals.setProductsPerPage(this.maxProductsPerPage);
    await this.props.globals.loadMoreProducts(this.getNumberOfVisibleProducts());
  }

  private renderPagination() {
    const {currentPage, handlePagination, totalProducts, styleParams} = this.props.globals;
    const totalPages = Math.ceil(totalProducts / this.maxProductsPerPage);

    return (
      <div data-hook="product-list-pagination">
        <Pagination
          {...paginationStyle('pagination')}
          currentPage={currentPage}
          onChange={({page}) => handlePagination(page)}
          paginationMode={styleParams.fonts.gallery_paginationFormat.value}
          showFirstLastNavButtons={styleParams.booleans.gallery_paginationFirstLastArrows}
          totalPages={totalPages}
        />
      </div>
    );
  }
}
